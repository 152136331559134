import * as React from "react"
import {graphql} from "gatsby";
import {useTranslation} from 'gatsby-plugin-react-i18next';

import FullWidthContainer from "../components/grid/FullWidthContainer";
import StageTeaser from "../components/molecules/StageTeaser/StageTeaser";
import ColoredBox from "../components/molecules/ColoredTextBox/ColoredBox";
import List from "../components/molecules/List/List";
import Text from "../components/atoms/Text/Text";
import Button from "../components/atoms/Button/Button";
import PageReferencesTitle from "../components/atoms/PageReferencesTitle/PageReferencesTitle";
import PageReferencesText from "../components/atoms/PageReferencesText/PageReferencesText";
import TranslatedLink from "../components/util/TranslatedLink";
import Seo from "../components/global/Seo/Seo";


type propType = {
	data: any
	classes: any,
	context: any
}

const CentersPage = ({data}: propType) => {
	const {t} = useTranslation();

	return (
		<>
			<Seo title={t('centers:seo.title')} description={t('centers:seo.description')} />
			<FullWidthContainer>
				<StageTeaser imageAlt={t('centers:imageAlt')}  image={data.stageTeaser} />
				<h1>{t('centers:mainHeadline')}</h1>
				<Text text={t('centers:sectionIntroduction.copy1')}/>
			</FullWidthContainer>
			<ColoredBox>
				<FullWidthContainer>
					<h2 className="margin-bottom-large--desktop-only no-text-transform">
						<Text text={t('centers:sectionCenters.headline')}/>
					</h2>
					<List list={t('centers:sectionCenters.list', {returnObjects: true})} />
				</FullWidthContainer>
			</ColoredBox>
			<FullWidthContainer>
				<TranslatedLink title={t('orderButton.title')} to="/order">
					<Button uppercase={true} inverted={true}>
						{t('centers:sectionReferences.buttonLabel')}
					</Button>
				</TranslatedLink>
				<PageReferencesTitle>
					<Text text="centers:sectionReferences.headline" />
				</PageReferencesTitle>
				<PageReferencesText>
					<Text text="centers:sectionReferences.copy" />
				</PageReferencesText>
				<PageReferencesText>
					<Text text="centers:sectionReferences.explanation" />
				</PageReferencesText>
				<PageReferencesText>
					<Text text="approvalCode" />
				</PageReferencesText>
			</FullWidthContainer>
		</>
	)
}

export default CentersPage

export const query = graphql`
	query($language: String!) {
		locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "centers"]}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		},
		stageTeaser: file(
			relativePath: { eq: "centers/StageTeaserDesktop.jpg"}
		) {
			id
			relativePath
			childImageSharp {
				gatsbyImageData(
					placeholder: BLURRED,
					formats: [AUTO, WEBP, AVIF],
					quality: 75,
					sizes: "(min-width: 1300px) 1292px, (min-width: 640px) 100vw,  200vw"

				)
			}
		}
	}
`;
